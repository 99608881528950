/**
 * Copyright (C) 2021 Prosperia Salud, S.A. DE C.V. info@prosperia.health.
 * All rights reserved
 * DO NOT REDISTRIBUTE
 * The software MAY NOT be rewritten or refactored by any means. The code MAY
 * NOT be packaged and redistributed by any means without prior written
 * permission from the author.
 */
import React, {
  useEffect,
  lazy,
  useContext,
  Suspense,
} from 'react';
/// /// EXTERNAL /// ///
// mui //
import { Grid } from '@mui/material';
// context //
import { PocParametersContext } from '@prosperia/context.poc-parameters-context';
// hook //
import { useResponsive } from '@prosperia/hooks.use-responsive';
// third party libraries //
import TagManager from 'react-gtm-module';
/// /// INTERNAL /// ///
// hook //
import useReport from 'hook/useReport';
// context //
import { InteractionContext } from 'context/InteractionContext';

const Blurred = lazy(() => import('components/atoms/common/Blurred'));
const NavBar = lazy(() => import('components/atoms/common/NavBar'));
const Footer = lazy(() => import('components/organisms/Footer'));
const Retinia = lazy(() => import('components/templates/Retinia'));

const Report = () => {
  const GTM_ID_RETINIA = 'GTM-N7C6D27';

  const { downSm, theme } = useResponsive();
  const { isLoaderPocParameters, pocParameters } = useContext(PocParametersContext);
  const { patient, report, interactionParameters } = useContext(InteractionContext);

  const {
    container: isContainer,
    notReportShareable,
    isBackgroundBlured,
    isSendByPhoneAvailable,
    isSkipAvailable,
    isNotContactEmailAvailable,
    isReferenceCode,
    referenceCode,
    modules,
  } = pocParameters;

  const isPhoneParams = isSendByPhoneAvailable;
  const notEmailParams = isNotContactEmailAvailable;
  const isContactDetailsParams = isPhoneParams || !notEmailParams;

  const istNotContactDetails = !patient.pacient_email && !patient.pacient_cellphone;

  const {
    interaction_times_visited: interactionTimesVisited,
    interaction_refered: interactionRefered,
    no_referal_cause_id: noReferalCauseId,
    interaction_id: interactionId,
    interaction_branch: interactionBranch,
    interaction_type: interactionType,
  } = interactionParameters;

  const istFirstInteractionVisited = interactionTimesVisited === 0;

  const isFirstPatientVisit = !notReportShareable && !isContainer && istFirstInteractionVisited;

  const isFirstReportSend = isContactDetailsParams && isFirstPatientVisit;

  const marginTopContainer = downSm ? '40px' : '60px';
  const maginTopThemeDefault = downSm ? theme.downSm.gapMain : theme.upSm.gapMain;

  const responsive = {
    container: {
      marginTop: isContainer ? marginTopContainer : maginTopThemeDefault,
    },
    footer: {
      paddingTop: '104px',
    },
  };

  useEffect(() => {
    const isProduction = process.env.NODE_ENV === 'production';
    if (isProduction) {
      TagManager.initialize({ gtmId: GTM_ID_RETINIA });
    }
  }, []);

  const {
    openSendReportModal,
    isCancelButtonSendReport,
    firstPatientVisit,
    setOpenSendReportModal,
    setCancelButtonSendReport,
    setFirstPatientVisit,
  } = useReport({
    isLoaderPocParameters,
    istFirstInteractionVisited,
    patient,
    isSkipAvailable,
    isFirstReportSend,
  });

  if (!report) return null;

  const isContactDetailsFirstVisit = !!firstPatientVisit;

  return (
    <Suspense fallback={<div />}>
      <Blurred
        isContactDetailsFirstVisit={isContactDetailsFirstVisit}
        isContainer={isContainer}
        isBackgroundBlured={isBackgroundBlured}
        openSendReportModal={openSendReportModal}
      >
        <NavBar
          isContainer={isContainer}
          parentStyle={responsive.container}
        />
        <Grid
          container
          sx={isContainer && responsive.container}
          justifyContent="center"
          item
          xs={12}
        >
          <Retinia
            interactionBranch={interactionBranch}
            isFirstInteractionVisited={istFirstInteractionVisited}
            isContainer={isContainer}
            isContactDetailsFirstVisit={isContactDetailsFirstVisit}
            interactionRefered={interactionRefered}
            isContactDetailsParams={isContactDetailsParams}
            noReferalCauseId={noReferalCauseId}
            report={report}
            interactionId={interactionId}
            isReferenceCode={isReferenceCode}
            referenceCode={referenceCode}
            modules={modules}
            setOpenSendReportModal={setOpenSendReportModal}
            setFirstPatientVisit={setFirstPatientVisit}
          />
        </Grid>
        <Grid item xs={12} sx={responsive.footer}>
          <Footer
            interactionType={interactionType}
            interactionBranch={interactionBranch}
            istNotContactDetails={istNotContactDetails}
            isContactDetailsParams={isContactDetailsParams}
            isContactDetailsFirstVisit={isContactDetailsFirstVisit}
            istFirstInteractionVisited={istFirstInteractionVisited}
            openSendReportModal={openSendReportModal}
            setOpenSendReportModal={setOpenSendReportModal}
            isCancelButtonSendReport={isCancelButtonSendReport}
            setCancelButtonSendReport={setCancelButtonSendReport}
            firstPatientVisit={firstPatientVisit}
            setFirstPatientVisit={setFirstPatientVisit}
          />
        </Grid>
      </Blurred>
    </Suspense>
  );
};

export default Report;
